export const getEmptyTask = () => {
    const settingStore = useSettingsStore();
    let settings = settingStore.getSetting;

    let empty_task = {
        'description': '',
        'script_type': settings?.admin_settings?.default_scripting_language ? settings?.admin_settings?.default_scripting_language : 'command',
        'script': '',
        'commands': [],
        'full_command': '',
        'tags': [],
        'input_params': [],
        'output_params': [],
        'title': '',
        'sub_tasks': [],
        'script': {
            'code': ''
        }
    }

    /*
    let dummy = JSON.parse(JSON.stringify(empty_task));
    empty_task.task = dummy;
    */
    
    return empty_task;
};
